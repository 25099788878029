import React, { FunctionComponent, useCallback } from 'react'
import { Chip, ChipGroup } from '@trr/common-component-library'

import s from './ChipsList.module.scss'

export interface ChipsListProps {
  chipsList: string[]
  removeChip: (chip: string) => void
}

export const ChipsList: FunctionComponent<ChipsListProps> = ({
  chipsList,
  removeChip,
}) => {
  const createRemoveChipHandler = useCallback(
    (chip: string) => () => {
      removeChip(chip)
    },
    [removeChip]
  )
  return (
    <div className={s.ChipsContainer}>
      <ChipGroup>
        {chipsList.map((chip, index) => {
          return (
            <Chip
              size="large"
              color="green"
              key={index}
              onRemove={createRemoveChipHandler(chip)}
            >
              {chip}
            </Chip>
          )
        })}
      </ChipGroup>
    </div>
  )
}
