import React, { FunctionComponent, useCallback, useState } from 'react'
import { Icons, Popover, Typography } from '@trr/common-component-library'

import { useEpiString, useStateSelector } from '../../Utils'
import { ProfileState } from '../../Store'

import { AdditionalInformationModal } from './AdditionalInformationModal/AdditionalInformationModal'
import { useTranslateAdditionalInfo } from './AdditionalInformationModal/Hooks/useTranslateAdditionalInfo'
import s from './AdditionalInformation.module.scss'
import { AdditionalInformationBox } from './AdditionalInformationBox'

export interface MoreInformationProps {
  profile: ProfileState
}
export const AdditionalInformation: FunctionComponent<
  MoreInformationProps
> = () => {
  const t = useEpiString()
  const [activeModal, setOpenModal] = useState(false)
  const [visible, setVisible] = useState(false)

  const additionalInformationState = useStateSelector(
    (state) => state.profile.additionalInformation
  )
  const translate = useTranslateAdditionalInfo

  const openCloseModal = useCallback(() => {
    setOpenModal(!activeModal)
  }, [activeModal])

  const additionalInformationArray = [
    {
      title: t('additionalInformation.extentsOfEmployment.title'),
      values: additionalInformationState.extentsOfEmployment.map((word) =>
        translate(word)
      ),
    },
    {
      title: t('additionalInformation.formsOfEmployment.title'),
      values: additionalInformationState.formsOfEmployment.map((word) =>
        translate(word)
      ),
    },

    {
      title: t('additionalInformation.otherPreferences.title'),
      values: additionalInformationState.otherPreferences.map((word) =>
        translate(word)
      ),
    },
  ]
  return (
    <div>
      <AdditionalInformationBox
        title={
          <div>
            <Typography variant="h2">
              {t('additionalInformation.title')}
              <Popover
                visible={visible}
                setVisible={setVisible}
                popperPlacement="bottom"
                referenceElement={
                  <button className={s.TooltipButton}>
                    <Icons.InformationFilledIcon
                      color={s.purple100}
                      size={16}
                    />
                  </button>
                }
              >
                <div className={s.Tooltip}>
                  <Typography variant="body2" className={s.TooltipText}>
                    {t('additionalInformation.toolTip')}
                  </Typography>
                </div>
              </Popover>
            </Typography>
          </div>
        }
        text={t('additionalInformation.text')}
        changeButton={t('additionalInformation.changeButton')}
        addButton={t('additionalInformation.addButton')}
        values={additionalInformationArray}
        action={openCloseModal}
      />
      {activeModal && (
        <AdditionalInformationModal closeModal={openCloseModal} />
      )}
    </div>
  )
}
