/* eslint-disable react/display-name */
import {
  BUTTON_TEXT_CLOSE,
  Dialog,
  EmptyResult,
  FilePickerButton,
  IconButton,
  Icons,
  Loading,
  Typography,
} from '@trr/common-component-library'
import React, { FunctionComponent } from 'react'

import { useEpiString } from '../../../Utils'
import { CvRadioList } from '../../../Shared'
import { CvState, ProfileState } from '../../../Store'
import { ModalStep } from '../Cv'

import s from './CvModal.module.scss'

interface DialogProps {
  modalStep: ModalStep
  handleUpload: () => void
  handleFilePicker: (files: FileList) => void
  handleFilePickerError: () => void
  handleCloseFileUploadErrorModal: () => void
  handleUploadFromTool: () => void
  handleCloseModal: () => void
  handleRemoveCV: () => void
  activeModal: boolean
  cvState: CvState
  profile: ProfileState
}

export const CvModal: FunctionComponent<DialogProps> = ({
  modalStep,
  handleUpload,
  handleFilePicker,
  handleFilePickerError,
  handleUploadFromTool,
  handleCloseFileUploadErrorModal,
  handleCloseModal,
  handleRemoveCV,
  activeModal,
  cvState,
  profile,
}) => {
  const t = useEpiString()
  const filePickerRef = React.useRef<HTMLDivElement>(null)

  const onTool = modalStep === ModalStep.Tool,
    onWarning = modalStep === ModalStep.Warning,
    onDefault = modalStep === ModalStep.Default,
    onDeactivated = modalStep === ModalStep.Deactivated,
    onFileUploadError = modalStep === ModalStep.FileUploadError,
    modalTitle = onDeactivated
      ? t('modal.title.deactivated')
      : onWarning
      ? t('cv.modal.warning.title')
      : onFileUploadError
      ? t('cv.modal.uploaderror.title')
      : t('cv.modal.title')

  const profileWillDeactivateText = profile.active && (
    <Typography className={s.Warning} variant="body1">
      <span>{t('cv.modal.deactivate.head')}</span>
      {t('cv.modal.deactivate.text')}
    </Typography>
  )

  const handleFilePickerForward = () => {
    const uploadButton = filePickerRef.current?.firstChild as HTMLButtonElement
    uploadButton.click()
  }

  const primaryAction = onTool
    ? cvState.cvList.length > 0
      ? handleUpload
      : () => null
    : onWarning || onDeactivated
    ? handleCloseModal
    : onFileUploadError
    ? handleCloseFileUploadErrorModal
    : onDefault
    ? handleFilePickerForward
    : () => null

  const primaryText = onTool
    ? cvState.cvList.length > 0
      ? t('cv.modal.cvTool.button')
      : ''
    : onWarning
    ? t(`cv.modal.warning.button.cancel`)
    : onDeactivated
    ? t('modal.button.deactivated')
    : onFileUploadError
    ? t('cv.modal.uploaderror.button')
    : onDefault
    ? t('cv.modal.button.upload')
    : ''

  const customButtons = onDefault
    ? [
        {
          text: t('cv.modal.button.cvAndLetter'),
          onClick: handleUploadFromTool,
        },
      ]
    : onWarning
    ? [
        {
          text: t('cv.modal.warning.button.delete'),
          onClick: handleRemoveCV,
        },
      ]
    : undefined

  const dialogProps =
    (onTool && cvState.cvList.length > 0) || !onTool
      ? {
          onConfirm: primaryAction,
          confirmText: primaryText,
          buttons: customButtons,
          onCancel: onFileUploadError
            ? handleCloseFileUploadErrorModal
            : onDeactivated || onWarning || onDefault
            ? undefined
            : handleCloseModal,
        }
      : { onClose: handleCloseModal }

  if (!activeModal) {
    return null
  }

  return (
    <Dialog
      heading={modalTitle}
      size={'medium'}
      className={onDefault ? s.cvDialog : onWarning ? s.cvDialogWarning : ''}
      {...dialogProps}
    >
      {onTool ? (
        cvState.loading ? (
          <Loading />
        ) : cvState.cvList.length > 0 ? (
          <CvRadioList list={cvState.cvList} name={'cv'} />
        ) : (
          <EmptyResult
            heading={t('cv.moda.list.empty.heading')}
            description={t('cv.moda.list.empty.text')}
            action={{
              text: t('cv.moda.list.empty.urlText'),
              link: t(`cv.link.url`),
            }}
          />
        )
      ) : onWarning ? (
        <>
          <Typography variant="body1">{t('cv.modal.warning.text')}</Typography>
          {profileWillDeactivateText}
        </>
      ) : onDeactivated ? (
        <Typography variant="body1">{t('modal.text.deactivated')}</Typography>
      ) : onFileUploadError ? (
        <Typography variant="body1">
          {t('cv.modal.uploaderror.body')}
        </Typography>
      ) : onDefault ? (
        <>
          <IconButton
            className={s.CloseButton}
            aria-label={BUTTON_TEXT_CLOSE}
            onClick={handleCloseModal}
          >
            <Icons.CloseIcon />
          </IconButton>
          <div ref={filePickerRef} className={s.uploadButton}>
            <FilePickerButton
              inputName="file"
              fileSizeLimit={5120}
              validExtentions={['pdf']}
              onFilePicked={handleFilePicker}
              onFileError={handleFilePickerError}
            >
              {' '}
            </FilePickerButton>
          </div>
          <Typography variant="body1">{t('cv.modal.text')}</Typography>
        </>
      ) : (
        <></>
      )}
    </Dialog>
  )
}
