import React, { FunctionComponent } from 'react'
import {
  Icons,
  Link,
  MetaData,
  Typography,
} from '@trr/common-component-library'
import { IUser } from 'App.types'
import { useEpiString } from 'Utils'

import { Box } from '../../Shared'

import s from './ContactInfo.module.scss'

export interface ContactProps {
  userData: IUser
}
export const ContactInfo: FunctionComponent<ContactProps> = ({ userData }) => {
  const t = useEpiString()
  const linkedInInfo = userData.socialMedia?.find(
    (media) => media.type === 'linkedin'
  )
  const updatedLinkedInInfo = userData.linkedin

  return (
    <div className={s.contactInfoBox}>
      <Box
        headerFontSize={'h2'}
        testId={'contactInfoTestId'}
        metaData={[
          <Typography variant="body1" className={s.userName} key="metaName">
            {userData.preferredFirstName
              ? `${userData.preferredFirstName} ${userData.lastName}`
              : `${userData.firstName} ${userData.lastName}`}
          </Typography>,
          <MetaData
            key="metaEmail"
            icon={<Icons.EnvelopeIcon color={s.textColor} />}
            text={userData.email}
          />,
          <MetaData
            key="metaPhone"
            icon={<Icons.PhoneIcon color={s.textColor} />}
            text={userData.mobilePhone}
          />,
          <MetaData
            key={
              (linkedInInfo && updatedLinkedInInfo?.length !== 0) ||
              updatedLinkedInInfo
                ? 'metaLinkedIn'
                : 'metaNoLinkedIn'
            }
            icon={
              (linkedInInfo && updatedLinkedInInfo?.length !== 0) ||
              updatedLinkedInInfo ? (
                <></>
              ) : (
                <Icons.LinkedInIcon color={s.textColor} />
              )
            }
            text={
              updatedLinkedInInfo?.length === 0 ? (
                <p className={s.noSocialMediaExisting}>
                  {t('socialMediaInfo.noMediaAdded')}
                </p>
              ) : updatedLinkedInInfo ? (
                <Link
                  customClass={s.LinkedIn}
                  external
                  noChevron
                  label="Linkedin"
                  link={updatedLinkedInInfo.replace(
                    /^(?:https?:\/\/)?(?:www\.)?/i,
                    'https://www.'
                  )}
                />
              ) : linkedInInfo ? (
                <Link
                  customClass={s.LinkedIn}
                  external
                  noChevron
                  label="Linkedin"
                  link={linkedInInfo.url.replace(
                    /^(?:https?:\/\/)?(?:www\.)?/i,
                    'https://www.'
                  )}
                />
              ) : (
                <p className={s.noSocialMediaExisting}>
                  {t('socialMediaInfo.noMediaAdded')}
                </p>
              )
            }
          />,
        ]}
        data-testid={'contactButtonTestId'}
        title={t('contactInfo.title')}
        text={
          <>
            {t('contactInfo.description')}
            &nbsp;
            <Link link={t('contactInfo.settingsUrl')} noChevron>
              {t('contactInfo.link.text')}
            </Link>
          </>
        }
      />
    </div>
  )
}
