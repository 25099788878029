import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'

import createRootReducer from './createRootReducer'
import history from './history'
import { initialProfileState } from './profile'
import { initialCvState } from './cv'
import { initialAutoCompleteState } from './autocomplete/initialAutocompleteState'
import { initialConsentState } from './consent'

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reduxDevToolsOptions: any = {}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevToolsOptions)
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(thunk, routerMiddleware(history))
)

export const initialState: RootState = {
  router: {
    location: {
      query: {},
      pathname: '',
      hash: '',
      key: '',
      search: '',
      state: null,
    },
    action: 'PUSH',
  },
  profile: initialProfileState,
  autocomplete: initialAutoCompleteState,
  consent: initialConsentState,
  cv: initialCvState,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configureStore = (preloadedState: any) =>
  createStore(createRootReducer(history), preloadedState, enhancer)

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>

export interface PayloadAction<T> {
  type: string
  payload: T
}
export const store = configureStore(initialState)
