export const downloadFile = (
  blob: Blob,
  filename: string,
  elementRef?: React.RefObject<HTMLElement>
) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  try {
    a.href = url
    a.target = '_blank'
    a.download = `${filename}`
    elementRef
      ? elementRef.current?.appendChild(a)
      : document.body.appendChild(a)
    a.click()
    a.remove()
    return true
  } catch (error) {
    return false
  }
}
export const previewFile = (
  blob: Blob,
  elementRef?: React.RefObject<HTMLElement>
) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  try {
    a.href = url
    a.target = '_blank'
    elementRef
      ? elementRef.current?.appendChild(a)
      : document.body.appendChild(a)
    a.click()
    a.remove()
    return true
  } catch (error) {
    return false
  }
}
