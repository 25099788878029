import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { profileReducer } from './profile'
import { autocompleteReducer } from './autocomplete'
import { consentReducer } from './consent'
import { cvReducer } from './cv'

const createRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    profile: profileReducer,
    autocomplete: autocompleteReducer,
    consent: consentReducer,
    cv: cvReducer,
  })
}

export default createRootReducer
