import React, { FunctionComponent, useContext } from 'react'
import {
  Button,
  ChipGroup,
  Chip,
  Icons,
  TruncateText,
  MetaDataGroup,
  Typography,
} from '@trr/common-component-library'

import { DeviceProviderContext } from '../../Utils/DeviceProvider'

import s from './Box.module.scss'

export interface BoxProps {
  title: string
  titleIcon?: React.ReactNode
  text?: JSX.Element
  values?: string[]
  button?: string
  action?: () => void
  onRemove?: () => void
  onClick?: () => void
  metaData?: JSX.Element[]
  testId?: string
  check?: boolean
  headerFontSize?: 'h2' | 'h4'
  notCompleteStepCharacter?: string
}

export const Box: FunctionComponent<BoxProps> = ({
  children,
  title,
  titleIcon,
  text,
  button,
  action,
  values = [],
  onRemove,
  onClick,
  check = true,
  metaData,
  testId,
  headerFontSize = 'h4',
  notCompleteStepCharacter,
}) => {
  const complete = (values.length > 0 || children) && check
  const valuesExist = values.length > 0
  const { isMobile } = useContext(DeviceProviderContext)
  return (
    <div className={s.Box}>
      <div className={s.Text}>
        <div className={s.TitleDiv}>
          {complete ? (
            <div className={s.Icon}>
              {titleIcon ?? (
                <Icons.CheckmarkCircleFilledIcon
                  size={24}
                  color={s.purple100}
                />
              )}
            </div>
          ) : notCompleteStepCharacter ? (
            <div className={s.Icon}>
              <span role="img" className={s.BoxStepIndicator}>
                {notCompleteStepCharacter}
              </span>
            </div>
          ) : (
            <></>
          )}
          <Typography variant={headerFontSize} className={s.Heading}>
            {title}
          </Typography>
        </div>
        {children ? (
          children
        ) : valuesExist ? (
          onRemove ? (
            <div className={s.CvButtonWrapper}>
              <button className={s.downloadCv} onClick={onClick}>
                <TruncateText lines={2}>{values[0]}</TruncateText>
              </button>
              <button className={s.CrossButton} onClick={onRemove}>
                <Icons.CloseIcon color={s.textColor} size={16} />
              </button>
            </div>
          ) : (
            <ChipGroup className={s.boxSelectedChips}>
              {values.map((value) => (
                <Chip key={value} color="green">
                  {value}
                </Chip>
              ))}
            </ChipGroup>
          )
        ) : (
          <Typography className={s.boxDescriptiveText} variant="body1">
            {text}
          </Typography>
        )}
        {metaData && (
          <MetaDataGroup>{metaData.map((element) => element)}</MetaDataGroup>
        )}
      </div>
      {action && button && (
        <Button
          testSelector={testId}
          secondary
          large={isMobile ? true : false}
          onClick={action}
          customClass={s.Button}
        >
          {button}
        </Button>
      )}
    </div>
  )
}
