import { Dialog, Typography } from '@trr/common-component-library'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateProfile } from 'Store'

import { CheckBoxGroup } from '../../../Shared/CheckBoxGroup/CheckBoxGroup'
import { useEpiString, useStateSelector } from '../../../Utils'

import s from './AdditionalInformationModal.module.scss'

export interface MoreInfoModalProps {
  closeModal: () => void
}
export const AdditionalInformationModal: FunctionComponent<
  MoreInfoModalProps
> = ({ closeModal }) => {
  const additionalInformationState = useStateSelector(
    (state) => state.profile.additionalInformation
  )
  const [formOfEmployment, setFormOfEmployment] = useState(
    additionalInformationState.formsOfEmployment
  )
  const [extentOfEmployment, setExtentOfEmployment] = useState(
    additionalInformationState.extentsOfEmployment
  )
  const [other, setOther] = useState(
    additionalInformationState.otherPreferences
  )
  const t = useEpiString()
  const dispatch = useDispatch()
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checkBoxInputData: string[] = JSON.parse(event.target.name)
      const isChecked = event.target.checked
      checkBoxInputData[1] === 'formsOfEmployment'
        ? isChecked
          ? setFormOfEmployment((oldArray) => [
              ...oldArray,
              checkBoxInputData[0],
            ])
          : setFormOfEmployment((oldArray) =>
              oldArray.filter((item) => item !== checkBoxInputData[0])
            )
        : checkBoxInputData[1] === 'extentsOfEmployment'
        ? isChecked
          ? setExtentOfEmployment((oldArray) => [
              ...oldArray,
              checkBoxInputData[0],
            ])
          : setExtentOfEmployment((oldArray) =>
              oldArray.filter((item) => item !== checkBoxInputData[0])
            )
        : isChecked
        ? setOther((oldArray) => [...oldArray, checkBoxInputData[0]])
        : setOther([])
    },
    []
  )
  const checkBoxData = {
    extent: [
      {
        label: 'Heltid',
        value: extentOfEmployment.some((val) => val === 'FullTime'),
        name: '["FullTime", "extentsOfEmployment"]',
      },
      {
        label: 'Deltid',
        value: extentOfEmployment.some((val) => val === 'PartTime'),
        name: '["PartTime", "extentsOfEmployment"]',
      },
    ],
    form: [
      {
        label: 'Anställd',
        value: formOfEmployment.some((val) => val === 'Employee'),
        name: '["Employee", "formsOfEmployment"]',
      },
      {
        label: 'Konsult',
        value: formOfEmployment.some((val) => val === 'Consultant'),
        name: '["Consultant", "formsOfEmployment"]',
      },
      {
        label: 'Frilans',
        value: formOfEmployment.some((val) => val === 'Freelance'),
        name: '["Freelance", "formsOfEmployment"]',
      },
    ],
    rest: [
      {
        label: 'Distans',
        value: other.some((val) => val === 'RemoteWork'),
        name: '["RemoteWork", "otherPreferences"]',
      },
    ],
  }

  const handleSave = useCallback(() => {
    dispatch(
      updateProfile({
        additionalInformation: {
          formsOfEmployment: formOfEmployment,
          extentsOfEmployment: extentOfEmployment,
          otherPreferences: other,
        },
      })
    )
    closeModal()
  }, [closeModal, dispatch, extentOfEmployment, formOfEmployment, other])

  return (
    <Dialog
      size="medium"
      heading={t('additionalInformation.modal.title')}
      confirmText={t('button.save')}
      onConfirm={handleSave}
      onCancel={closeModal}
    >
      <Typography variant="body1">
        {t('additionalInformation.modal.description')}
      </Typography>
      <div className={s.description}>
        <Typography variant="body1">
          {t('additionalInformation.modal.description2')}
        </Typography>
      </div>
      <CheckBoxGroup
        checkBoxes={checkBoxData.extent}
        title={t('additionalInformation.modal.extent')}
        handleChange={handleChange}
      />
      <CheckBoxGroup
        checkBoxes={checkBoxData.form}
        title={t('additionalInformation.modal.form')}
        handleChange={handleChange}
      />
      <CheckBoxGroup
        checkBoxes={checkBoxData.rest}
        title={t('additionalInformation.modal.rest')}
        handleChange={handleChange}
      />
    </Dialog>
  )
}
