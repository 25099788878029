import reduceReducers from 'reduce-reducers'
import { Reducer, AnyAction } from 'redux'

import { consentUpdateReducer } from './update'
import { ConsentState } from './types'

export * from './types'
export * from './initialConsentState'
export * from './update'

export const consentReducer = reduceReducers<ConsentState>(
  consentUpdateReducer
  // Cast with second generic argument to make combineReducers accept it.
) as Reducer<ConsentState, AnyAction>
