import { uniq } from 'ramda'
import { AnyAction, Dispatch, Reducer } from 'redux'
import { Action, createActions, handleActions } from 'redux-actions'

import { get, ErrorResponse } from '../../Utils'

import { initialAutoCompleteState } from './initialAutocompleteState'
import {
  ApiAutocompleteState,
  IAutocompleteLocations,
  AutocompleteErrorState,
  ILocatedIn,
} from './types'

export const {
  loadAutocompleteLocationInitiated,
  loadAutocompleteLocationFulfilled,
  loadAutocompleteLocationRejected,
  loadCountriesInitiated,
  loadCountriesFulfilled,
  loadCountriesRejected,
} = createActions({
  loadAutocompleteLocationInitiated: () => ({}),
  loadAutocompleteLocationFulfilled: (payload: IAutocompleteLocations) =>
    payload,
  loadAutocompleteLocationRejected: (payload: AutocompleteErrorState) =>
    payload,
  loadCountriesInitiated: () => ({}),
  loadCountriesFulfilled: (payload: ILocatedIn[]) => payload,
  loadCountriesRejected: (payload: AutocompleteErrorState) => payload,
})

export const loadAutocompleteLocation =
  (term: string) => (dispatch: Dispatch<AnyAction>) => {
    if (!term) {
      return dispatch(loadAutocompleteLocationFulfilled([]))
    } else {
      return get({
        url: `/ReferenceData/locations/suggest/${term}`,
        dispatch,
        initiated: loadAutocompleteLocationInitiated,
        fulfilled: loadAutocompleteLocationFulfilled,
        rejected: loadAutocompleteLocationRejected,
      })
    }
  }

export const loadCountryLocations =
  (term: string) => (dispatch: Dispatch<AnyAction>) =>
    get({
      url: `/ReferenceData/locations/suggest/${term}`,
      dispatch,
      initiated: loadCountriesInitiated,
      fulfilled: loadCountriesFulfilled,
      rejected: loadCountriesRejected,
    })

const parseCountries = (
  state: ApiAutocompleteState,
  newCountries: ILocatedIn[]
): ILocatedIn[] =>
  uniq([...state.countries.response.locationInformation, ...newCountries])

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const locationsAutocompleteReducer = handleActions<any>(
  {
    [loadAutocompleteLocationInitiated.toString()]: (
      state: ApiAutocompleteState
    ) => ({
      ...state,
      locations: {
        ...initialAutoCompleteState,
        loading: true,
        error: null,
      },
    }),
    [loadAutocompleteLocationFulfilled.toString()]: (
      state: ApiAutocompleteState,
      action: Action<IAutocompleteLocations>
    ) => ({
      ...state,
      locations: {
        response: {
          locationInformation: action.payload,
        },
        loading: false,
      },
    }),
    [loadAutocompleteLocationRejected.toString()]: (
      state: ApiAutocompleteState,
      action: Action<ErrorResponse>
    ) => ({
      ...state,
      locations: {
        loading: false,
        error: {
          status: action.payload.status,
        },
      },
    }),
    [loadCountriesFulfilled.toString()]: (
      state: ApiAutocompleteState,
      action: Action<ILocatedIn[]>
    ) => ({
      ...state,
      countries: {
        response: {
          locationInformation: parseCountries(state, action.payload),
        },
        loading: false,
      },
    }),
    [loadCountriesRejected.toString()]: (
      state: ApiAutocompleteState,
      action: Action<ErrorResponse>
    ) => ({
      ...state,
      countries: {
        loading: false,
        error: {
          status: action.payload.status,
        },
      },
    }),
  },

  initialAutoCompleteState
) as Reducer<ApiAutocompleteState>
