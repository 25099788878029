import React from 'react'

export interface KeyRouteProps {
  urlKey: string
}

export const KeyRoute: React.FunctionComponent<KeyRouteProps> = ({
  children,
}) => {
  return <>{children}</>
}
