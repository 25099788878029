/* eslint-disable react/jsx-no-bind */
import {
  Dialog,
  Icons,
  ISelectOption,
  Typography,
} from '@trr/common-component-library'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { uniq } from 'ramda'
import debounce from 'lodash.debounce'

import { Autocomplete, ChipsList } from '..'
import { useEpiString, useStateSelector } from '../../Utils'
import { IJobTitle, ProfileState } from '../../Store'

import s from './MatchingModal.module.scss'

export interface MatchingModalProps {
  chipList?: IJobTitle[]
  closeModal: () => void
  autocomplete: { name: string; type?: string; id?: string }[]
  onSave?: (value: IJobTitle[]) => void
  autocompleteDisptach: (value: string) => void
  profile: ProfileState
  currentInput: string
}
export enum ModalStep {
  FromTool,
  Deactivated,
}
export const MatchingModal: FunctionComponent<MatchingModalProps> = ({
  chipList = [],
  closeModal,
  autocomplete,
  onSave,
  autocompleteDisptach,
  profile,
  currentInput,
}) => {
  const t = useEpiString()
  const [warning, setWarning] = useState(false)
  const [choosenChips, setChoosenChips] = useState<IJobTitle[]>(chipList)
  const [removedChip, setRemovedChip] = useState(false)
  const [modalStep, setModalStep] = useState(ModalStep.FromTool)
  const [options, setOptions] = useState<ISelectOption[]>([])

  const isLoading = useStateSelector(
    (state) => state.autocomplete.professions.loading
  )

  const { active } = profile

  const onFromTool = modalStep === ModalStep.FromTool,
    onDeactivated = modalStep === ModalStep.Deactivated

  useEffect(() => {
    if (autocomplete && autocomplete.length !== 0) {
      setOptions(
        autocomplete.flatMap((opt) => {
          if (chipList) {
            const hasPickedChipInAutoComplete = choosenChips
              .map((chip) => chip.name)
              .includes(opt.name.replace(/(<([^>]+)>)/gi, ''))
            if (hasPickedChipInAutoComplete) {
              return []
            }
          }

          return {
            value: opt.name,
            label: opt.name,
            id: opt.id,
          }
        })
      )
    }
    if (autocomplete && autocomplete.length === 0) {
      setOptions([])
    }
  }, [autocomplete, chipList, choosenChips])

  useEffect(() => {
    if (chipList && choosenChips.length === 0 && removedChip && active) {
      setWarning(true)
      setRemovedChip(false)
    }
  }, [choosenChips, removedChip, active, chipList])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleType = useCallback(
    debounce((value: string) => {
      autocompleteDisptach(value)
    }, 300),
    [autocompleteDisptach]
  )
  const handleChange = useCallback(
    (option: ISelectOption) => {
      setOptions([])
      setWarning(false)
      const filteredOption = option.label.replace(/(<([^>]+)>)/gi, '')

      if (chipList) {
        const filteredJobtitle: IJobTitle = {
          name: filteredOption,
          id: option.id as string,
        }

        setChoosenChips((prevState) => uniq([...prevState, filteredJobtitle]))
      }
    },

    [chipList]
  )

  const handleRemoveChip = useCallback(
    (chipToRemove: string) => {
      if (chipList) {
        setChoosenChips((arr) =>
          arr.filter((chip) => chip.name !== chipToRemove)
        )
      }

      setRemovedChip(true)
    },
    [chipList]
  )

  const handleSave = useCallback(() => {
    if (chipList && onSave) {
      onSave(choosenChips)
    }

    setWarning(false)
    if (active && chipList && choosenChips.length === 0) {
      setModalStep(ModalStep.Deactivated)
    } else {
      closeModal()
    }
  }, [chipList, onSave, active, choosenChips, closeModal])

  return (
    <>
      {onFromTool && (
        <Dialog
          size="medium"
          heading={t('positions.modal.title')}
          onConfirm={handleSave}
          confirmText={t('button.save')}
          onCancel={closeModal}
          className={s.autocompleteModal}
        >
          <div className={s.MigrationContainer}>
            <Autocomplete
              placeholder={t('positions.autocomplete.placeholder')}
              label={t('positions.autocomplete.label')}
              options={options ?? []}
              onType={handleType}
              onChange={handleChange}
              icon={<Icons.MagnifierIcon />}
              showNoResult={
                currentInput?.length > 1 && !options.length && !isLoading
              }
            />
            {warning && active && (
              <p className={s.Warning}>
                <span>{t('positions.modal.warning.head')}</span>
                {t('positions.modal.warning.text')}
              </p>
            )}
            {chipList && choosenChips?.length > 0 && (
              <ChipsList
                chipsList={choosenChips.map((chip) => chip.name)}
                removeChip={handleRemoveChip}
              />
            )}
          </div>
        </Dialog>
      )}
      {onDeactivated && (
        <Dialog
          size="medium"
          heading={t('modal.title.deactivated')}
          onConfirm={closeModal}
        >
          <Typography variant="body1">{t('modal.text.deactivated')}</Typography>
        </Dialog>
      )}
    </>
  )
}
