import React, { FunctionComponent } from 'react'
import { Dialog } from '@trr/common-component-library'
import { useEpiString } from 'Utils'

import s from './ActivationModal.module.scss'

export interface ActivationModalProps {
  onCancel: () => void
  onConfirm: () => void
}

export const ActivationModal: FunctionComponent<ActivationModalProps> = ({
  onCancel,
  onConfirm,
}) => {
  const t = useEpiString()
  return (
    <Dialog
      size="medium"
      className={s.ActivationModal}
      heading={t('activation.modal.title')}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmText={t('activation.modal.button.confirm')}
    >
      <div dangerouslySetInnerHTML={{ __html: t('activation.modal.body') }} />
    </Dialog>
  )
}
