import {
  RadioButton,
  RadioButtonGroup,
  TruncateText,
  Typography,
} from '@trr/common-component-library'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { useEpiString, useOnMount } from '../../Utils'
import { CvApiModel, setChosenCV } from '../../Store'

import s from './CvRadioList.module.scss'

export interface RadioListProps {
  list: CvApiModel[]
  name: string
}
export const CvRadioList: FunctionComponent<RadioListProps> = ({
  list,
  name,
}) => {
  const t = useEpiString()
  const dispatch = useDispatch()
  useOnMount(() => {
    if (list.length > 0) {
      dispatch(
        setChosenCV({
          id: list[0].pdfId,
          name: list[0].fileName,
        })
      )
    }
  })
  const createChangeHandler = (item: CvApiModel) => () => {
    dispatch(
      setChosenCV({
        id: item.pdfId,
        name: item.fileName,
      })
    )
  }

  return (
    <RadioButtonGroup customClass={s.CvRadioList}>
      <Typography variant="body1">{t('cv.modal.tool.description')}</Typography>
      {list.map((item, index) => {
        return (
          <div key={item.pdfId} className={s.RadioButtonDiv}>
            <TruncateText lines={2}>
              <RadioButton
                onChange={createChangeHandler(item)}
                name={name}
                label={item.fileName}
                defaultChecked={index === 0 ? true : false}
              />
            </TruncateText>
          </div>
        )
      })}
    </RadioButtonGroup>
  )
}
