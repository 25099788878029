import { ProfileState } from './types'

export const initialProfileState: ProfileState = {
  id: '',
  active: false,
  isValid: false,
  nonActiveReasons: [],
  positions: [],
  jobTitles: [],
  isSavedAfterMigration: true,
  isAutoMigrated: false,
  locationInformations: [],
  cvPdf: null,
  loading: false,
  error: null,
  additionalInformation: {
    extentsOfEmployment: [],
    formsOfEmployment: [],
    otherPreferences: [],
  },
}
