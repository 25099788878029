import { useSelector } from 'react-redux'

import { RootState } from '../Store'

/**
 * Use a selector to fetch data from our typed root store.
 * @param selector The selector function, sent to useSelector internally.
 */
export const useStateSelector = <TResult>(
  selector: (state: RootState) => TResult
) => useSelector<RootState, TResult>(selector)
