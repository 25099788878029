/* eslint-disable no-undef */
interface IConfiguration {
  API_URL: string
  AUTHORITY_URL: string
  MEDIA_URL: string
}

// This configuration will be used for local development
const compiletimeConfiguration = {
  API_URL: process.env.API_URL ?? '',
  AUTHORITY_URL: process.env.AUTHORITY_URL ?? '',
  MEDIA_URL: process.env.MEDIA_URL ?? '',
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
  REPLACEABLE: 'CONFIG',
}

export const getConfig = (): IConfiguration => ({
  ...compiletimeConfiguration,
  ...APP_CONFIGURATION,
})
