import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from '@trr/common-component-library'

import { Box } from '../../Shared'
import { useEpiString, useStateSelector } from '../../Utils'
import {
  loadCvFile,
  loadCvList,
  ProfileState,
  updateProfile,
  uploadPdf,
} from '../../Store'

import { CvModal } from './CvModal'
import s from './Cv.module.scss'

export interface CvProps {
  profile: ProfileState
}

export enum ModalStep {
  Deactivated,
  Tool,
  Warning,
  Default,
  FileUploadError,
}

export const Cv: FunctionComponent<CvProps> = ({ profile }) => {
  const dispatch = useDispatch()
  const t = useEpiString()
  const [activeModal, setOpenModal] = useState(false)
  const [modalStep, setModalStep] = useState(ModalStep.Default)
  const cvState = useStateSelector((state) => state.cv)

  useEffect(() => {
    if (cvState.uploadedCv?.pdfId) {
      dispatch(
        updateProfile({
          cvPdf: {
            id: cvState.uploadedCv.pdfId,
            name: cvState.uploadedCv.fileName,
          },
        })
      )
    }
  }, [cvState, dispatch])

  const handleUpload = useCallback(() => {
    dispatch(updateProfile({ cvPdf: cvState.selectedCv }))
    setOpenModal(false)
    setModalStep(ModalStep.Default)
  }, [cvState.selectedCv, dispatch])

  const handleUploadFromTool = useCallback(() => {
    setModalStep(ModalStep.Tool)
    dispatch(loadCvList())
  }, [dispatch])

  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
    setModalStep(ModalStep.Default)
  }, [])

  const handleCloseFileUploadErrorModal = useCallback(() => {
    setModalStep(ModalStep.Default)
  }, [])

  const handleFilePicker = useCallback(
    (files: FileList) => {
      dispatch(uploadPdf(files[0]))
      setOpenModal(false)
      setModalStep(ModalStep.Default)
    },
    [dispatch]
  )

  const handleFilePickerError = useCallback(() => {
    setModalStep(ModalStep.FileUploadError)
  }, [])

  const handleOpenRemoveModal = useCallback(() => {
    setOpenModal(true)
    setModalStep(ModalStep.Warning)
  }, [])

  const handleRemoveCV = useCallback(() => {
    if (profile.active) {
      setModalStep(ModalStep.Deactivated)
      setOpenModal(true)
    } else {
      setModalStep(ModalStep.Default)
      setOpenModal(false)
    }
    dispatch(updateProfile({ cvPdf: null }))
  }, [dispatch, profile.active])

  const handleDownloadCV = useCallback(() => {
    dispatch(loadCvFile(profile.cvPdf?.id, profile.cvPdf?.name))
  }, [dispatch, profile.cvPdf])

  return (
    <>
      <Box
        title={t('cv.title')}
        text={
          <>
            {t('cv.text')}
            <Link
              customClass={s.HideExternal}
              noChevron
              link={t(`cv.link.url`)}
            >
              {t('cv.link.text')}
            </Link>
          </>
        }
        notCompleteStepCharacter="3"
        button={profile.cvPdf ? t('cv.button.change') : t('cv.button.upload')}
        values={profile.cvPdf ? [profile.cvPdf.name] : []}
        // eslint-disable-next-line react/jsx-no-bind
        action={() => setOpenModal(true)}
        onRemove={handleOpenRemoveModal}
        onClick={handleDownloadCV}
      />
      <CvModal
        modalStep={modalStep}
        handleUpload={handleUpload}
        handleUploadFromTool={handleUploadFromTool}
        handleCloseModal={handleCloseModal}
        handleCloseFileUploadErrorModal={handleCloseFileUploadErrorModal}
        handleFilePicker={handleFilePicker}
        handleFilePickerError={handleFilePickerError}
        handleRemoveCV={handleRemoveCV}
        cvState={cvState}
        activeModal={activeModal}
        profile={profile}
      />
    </>
  )
}
