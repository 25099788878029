import { createActions, Action, handleActions } from 'redux-actions'
import { Reducer } from 'redux'
import { loadProfile } from 'Store/profile'

import { put, ErrorResponse } from '../../Utils'
import { ThunkAction } from '../types'

import { ApiConsentModel, ConsentState } from './types'
import { initialConsentState } from './initialConsentState'

export const {
  updateConsentInitiated,
  updateConsentFulfilled,
  updateConsentRejected,
} = createActions({
  updateConsentInitiated: () => undefined,
  updateConsentFulfilled: (payload: ApiConsentModel) => payload,
  updateConsentRejected: (payload: ErrorResponse) => payload,
})

export const updateConsent =
  (payload: boolean): ThunkAction =>
  (dispatch) => {
    const body: ApiConsentModel = {
      consent: payload,
    }
    return put({
      body: JSON.stringify(body),
      dispatch,
      url: `/client-match/profile/consent`,
      initiated: updateConsentInitiated,
      fulfilled: updateConsentFulfilled,
      rejected: updateConsentRejected,
    }).then(() => dispatch(loadProfile()))
  }

// Must use any to allow reducers to have different payload types.
// Fix by explicitly typing result as correct type below.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const consentUpdateReducer = handleActions<any>(
  {
    [updateConsentInitiated.toString()]: (state: ConsentState) => ({
      ...state,
      error: null,
    }),
    [updateConsentFulfilled.toString()]: (
      state: ConsentState,
      action: Action<ApiConsentModel>
    ) => ({
      ...state,
      hasConsented: action.payload.consent,
    }),
    [updateConsentRejected.toString()]: (
      state: ConsentState,
      action: Action<ErrorResponse>
    ) => ({
      ...state,
      error: {
        status: action.payload.status,
      },
    }),
  },

  initialConsentState
) as Reducer<ConsentState>
