import { ApiAutocompleteState } from './types'

export const initialAutoCompleteState: ApiAutocompleteState = {
  locations: {
    response: { locationInformation: [] },
    error: null,
    loading: false,
  },
  professions: {
    response: {
      suggestions: [
        {
          id: '',
          jobTitle: '',
          jobTitleWithHighLightedSearchTerm: '',
        },
      ],
    },
    error: null,
    loading: false,
  },
  countries: {
    response: { locationInformation: [] },
    error: null,
    loading: false,
  },
}
