import reduceReducers from 'reduce-reducers'
import { Reducer, AnyAction } from 'redux'

import { profileLoadReducer } from './load'
import { profileUpdateReducer } from './update'
import { ProfileState } from './types'

export { loadProfile } from './load'
export { updateProfile } from './update'
export * from './types'
export * from './initialProfileState'

export const profileReducer = reduceReducers<ProfileState>(
  profileLoadReducer,
  profileUpdateReducer
  // Cast with second generic argument to make combineReducers accept it.
) as Reducer<ProfileState, AnyAction>
