import React from 'react'

export const AppShellRouter: React.FunctionComponent<{
  currentKey: string
}> = ({ currentKey, children }) => {
  let component = null

  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.props.urlKey === currentKey) {
        component = child
      }
    } else {
      component = null
    }
  })

  return component
}
