import {
  Checkbox,
  Dialog,
  Icons,
  ISelectOption,
  Typography,
} from '@trr/common-component-library'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { allPass, any, propEq, reject } from 'ramda'
import debounce from 'lodash.debounce'

import { useEpiString, useOnMount, useStateSelector } from '../../Utils'
import { ProfileState } from '../../Store'
import {
  EnglishToSwedishLocationTypes,
  SwedishToEnglishLocationTypes,
} from '../../Utils/Helpers'
import { ILocatedIn } from '../../Store/autocomplete/types'
import { Autocomplete, ChipsList } from '..'

import s from './MatchingModal.module.scss'

import { ModalStep } from '.'

export interface LocationModalProps {
  chipsListDetailed: ILocatedIn[]
  closeModal: () => void
  autocomplete: { name: string; type?: string }[]
  onSaveDetailed: (locations: ILocatedIn[]) => void
  autocompleteDisptach: (value: string) => void
  profile: ProfileState
  allowCreate?: boolean
  selectedCountries: ILocatedIn[]
  searchCountries: () => void
}

interface ILocationCheckbox {
  id: string
  name: string
  checked: boolean
}

export const LocationModal: FunctionComponent<LocationModalProps> = ({
  chipsListDetailed,
  closeModal,
  autocomplete,
  onSaveDetailed,
  autocompleteDisptach,
  profile,
  allowCreate,
  selectedCountries,
  searchCountries,
}) => {
  const t = useEpiString()
  const [warning, setWarning] = useState(false)
  const [choosenChipsDetailed, setChoosenChipsDetailed] = useState<
    ILocatedIn[]
  >([])
  const [removedChip, setRemovedChip] = useState(false)
  const [modalStep, setModalStep] = useState(ModalStep.FromTool)
  const [options, setOptions] = useState<ISelectOption[]>([])
  const [currentInput, setCurrentInput] = useState('')
  const [countryCheckboxes, setCountryCheckboxes] = useState<
    ILocationCheckbox[]
  >([])
  const [countriesHasBeenSearched, setCountriesHasBeenSearched] =
    useState(false)
  const autocompleteLocation = useStateSelector(
    (state) => state.autocomplete.locations.response?.locationInformation
  )
  const isLoading = useStateSelector(
    (state) => state.autocomplete.locations.loading
  )
  const countries = useStateSelector(
    (state) => state.autocomplete.countries.response?.locationInformation
  )

  useEffect(() => {
    if (countries.length > 0) {
      setCountryCheckboxes(
        countries
          .filter((country) => country.type.toLowerCase() === 'country')
          .map((country) => ({
            id: country.id,
            name: country.name,
            checked: selectedCountries.some((c) => c.name === country.name),
          }))
      )
    } else if (countries.length === 0 && !countriesHasBeenSearched) {
      searchCountries()
      setCountriesHasBeenSearched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])

  useEffect(() => {
    if (autocomplete && autocomplete.length !== 0) {
      setOptions(
        autocomplete
          .filter((a) => a.type?.toLowerCase() !== 'country')
          .flatMap((opt) => {
            const pickedLocation = {
              name: opt.name.replace(/(<([^>]+)>)/gi, ''),
              type: opt.type,
            }

            const hasPickedChipInAutoComplete = any(
              allPass([
                propEq('name', pickedLocation.name),
                propEq('type', pickedLocation.type),
              ])
            )(choosenChipsDetailed)
            if (hasPickedChipInAutoComplete) {
              return []
            }
            return {
              value: opt.type
                ? `${opt.name}, ${EnglishToSwedishLocationTypes(opt.type)}`
                : opt.name,
              label: opt.type
                ? `${opt.name}, ${EnglishToSwedishLocationTypes(opt.type)}`
                : opt.name,
            }
          })
      )
    }
    if (autocomplete && autocomplete.length === 0) {
      setOptions([])
    }
  }, [autocomplete, chipsListDetailed, choosenChipsDetailed])

  useOnMount(() => {
    if (chipsListDetailed.length > 0) {
      setChoosenChipsDetailed(
        chipsListDetailed.filter((c) => c.type.toLowerCase() !== 'country')
      )
    }
  })

  useEffect(() => {
    if (
      choosenChipsDetailed.length === 0 &&
      removedChip &&
      profile.active &&
      countryCheckboxes.filter((c) => c.checked).length === 0
    ) {
      setWarning(true)
      setRemovedChip(false)
    }
  }, [choosenChipsDetailed, removedChip, profile.active, countryCheckboxes])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleType = useCallback(
    debounce((value: string) => {
      autocompleteDisptach(value)
      setCurrentInput(value)
    }, 300),
    [autocompleteDisptach]
  )

  const handleChange = useCallback(
    (option: ISelectOption) => {
      setOptions([])
      setWarning(false)
      setCurrentInput('')
      const filteredOption = option.label.replace(/(<([^>]+)>)/gi, '')

      const pickedLocation = {
        name: filteredOption.split(',')[0],
        type: SwedishToEnglishLocationTypes(
          filteredOption.split(',')[1].trim()
        ),
      }
      const detailedLocation = autocompleteLocation.find(
        (loc) =>
          loc.name === pickedLocation.name && loc.type === pickedLocation.type
      )

      detailedLocation &&
        setChoosenChipsDetailed((prevState) => [...prevState, detailedLocation])
    },

    [autocompleteLocation]
  )

  const handleRemoveChip = useCallback((chipToRemove: string) => {
    const chipToRemoveName = chipToRemove.split(',')[0]
    const chipToRemoveType =
      chipToRemove.split(',')[1] &&
      SwedishToEnglishLocationTypes(chipToRemove.split(',')[1]?.trim())

    setChoosenChipsDetailed((prevState) => [
      ...reject(
        allPass([
          propEq('name', chipToRemoveName),
          propEq('type', chipToRemoveType),
        ]),
        prevState
      ),
    ])
    setRemovedChip(true)
  }, [])

  const handleSave = useCallback(() => {
    let combinedChosenLocations: ILocatedIn[] =
      choosenChipsDetailed.concat(selectedCountries)
    countryCheckboxes.forEach((c) => {
      if (
        c.checked &&
        !selectedCountries.some((country) => country.name === c.name)
      ) {
        combinedChosenLocations.push(
          countries?.filter((country) => country.name === c.name)[0]
        )
      } else if (
        !c.checked &&
        selectedCountries.some((chip) => chip.name === c.name)
      ) {
        combinedChosenLocations = combinedChosenLocations.filter(
          (chip) => chip.name !== c.name
        )
      }
    })
    onSaveDetailed(combinedChosenLocations)
    setWarning(false)
    if (combinedChosenLocations.length === 0 && profile.active) {
      setModalStep(ModalStep.Deactivated)
    } else {
      closeModal()
    }
  }, [
    choosenChipsDetailed,
    selectedCountries,
    countryCheckboxes,
    onSaveDetailed,
    profile.active,
    countries,
    closeModal,
  ])

  const onFromTool = modalStep === ModalStep.FromTool,
    onDeactivated = modalStep === ModalStep.Deactivated

  const handleCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const currentCheckboxes = countryCheckboxes.map((c) =>
        event.currentTarget.name === c.id ? { ...c, checked: !c.checked } : c
      )
      setCountryCheckboxes(currentCheckboxes)
      if (
        currentCheckboxes.find((c) => c.id === event.currentTarget.name)
          ?.checked === false
      ) {
        setRemovedChip(true)
      }
    },
    [countryCheckboxes, setCountryCheckboxes, setRemovedChip]
  )
  return (
    <>
      {onFromTool && (
        <Dialog
          size="medium"
          heading={t('locations.modal.title')}
          onConfirm={handleSave}
          confirmText={t('button.save')}
          onCancel={closeModal}
          className={s.autocompleteModal}
        >
          <Autocomplete
            placeholder={t('locations.autocomplete.placeholder')}
            label={t('locations.autocomplete.label')}
            options={options}
            onType={handleType}
            icon={<Icons.MagnifierIcon />}
            onChange={handleChange}
            allowCreate={allowCreate}
            showNoResult={
              currentInput?.length > 1 && !options.length && !isLoading
            }
          />
          {chipsListDetailed && choosenChipsDetailed?.length > 0 && (
            <ChipsList
              chipsList={choosenChipsDetailed.map(
                (chip) =>
                  `${chip.name}, ${EnglishToSwedishLocationTypes(chip.type)}`
              )}
              removeChip={handleRemoveChip}
            />
          )}
          {countries.length > 0 && (
            <div className={s.AdditionalContentWrapper}>
              <Typography variant="body1">
                {t('location.modal.additional.countries')}
              </Typography>
              {countryCheckboxes.map((c, index) => (
                <Checkbox
                  name={c.id}
                  customClass={s.checkbox}
                  key={index}
                  label={c.name}
                  onChange={handleCheckbox}
                  checked={c.checked}
                />
              ))}
            </div>
          )}
          {warning && profile.active && (
            <p className={s.Warning}>
              <span>{t('locations.modal.warning.head')}</span>
              {t('locations.modal.warning.text')}
            </p>
          )}
        </Dialog>
      )}
      {onDeactivated && (
        <Dialog
          size="medium"
          heading={t('modal.title.deactivated')}
          onConfirm={closeModal}
          confirmText={t('modal.button.deactivated')}
          onCancel={closeModal}
        >
          <Typography variant="body1">{t('modal.text.deactivated')}</Typography>
        </Dialog>
      )}
    </>
  )
}
