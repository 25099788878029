import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { IUser } from '../../App.types'
import { useOnMount, useStateSelector } from '../../Utils'
import {
  JobTitles,
  Locations,
  Cv,
  Activation,
  AdditionalInformation,
  ShowProfile,
} from '../../Features'
import { loadProfile } from '../../Store'
import { ContactInfo } from '../../Features/ContactInfo'

import s from './LandingPage.module.scss'

interface LandingPageProps {
  userData: IUser
}

export const LandingPage: FunctionComponent<LandingPageProps> = ({
  userData,
}) => {
  const profile = useStateSelector((state) => state.profile)
  const dispatch = useDispatch()
  useOnMount(() => dispatch(loadProfile()))

  return (
    <>
      <Activation />
      <ShowProfile userData={userData} />
      <hr />

      <div className={s.Boxes}>
        <JobTitles profile={profile} />
        <Locations profile={profile} />
        <Cv profile={profile} />
      </div>
      <div className={s.Boxes}>
        <hr />
        <AdditionalInformation profile={profile} />
        <hr />
        <ContactInfo userData={userData} />
      </div>
    </>
  )
}
