import React, { FunctionComponent, useContext } from 'react'
import {
  Button,
  ChipGroup,
  Chip,
  Typography,
} from '@trr/common-component-library'

import { DeviceProviderContext } from '../../Utils/DeviceProvider'

import s from './AdditionalInformationBox.module.scss'

export interface AdditionalInformationBoxProps {
  title: JSX.Element
  text?: string
  values?: { title: string; values: string[] }[]
  changeButton: string
  addButton: string
  action: () => void
}

export const AdditionalInformationBox: FunctionComponent<AdditionalInformationBoxProps> =
  ({ title, text, action, changeButton, addButton, values = [] }) => {
    const { isMobile } = useContext(DeviceProviderContext)

    const numberOfValues = values.filter(
      (additionalInfo) => additionalInfo.values.length > 0
    )
    return (
      <div className={s.Box}>
        <div className={s.Text}>
          <div className={s.TitleDiv}>
            <h4 className={s.Heading}>{title}</h4>
          </div>
          {numberOfValues.length > 0 ? (
            values.map(
              (additionalInfo, index) =>
                additionalInfo.values.length > 0 && (
                  <div key={index}>
                    <h5 className={s.header}>{additionalInfo.title}</h5>
                    <ChipGroup>
                      {additionalInfo.values.map((val) => (
                        <Chip key={val} color="green">
                          {val}
                        </Chip>
                      ))}
                    </ChipGroup>
                  </div>
                )
            )
          ) : (
            <Typography variant="body1">{text}</Typography>
          )}
        </div>

        <Button
          secondary
          large={isMobile ? true : false}
          onClick={action}
          customClass={s.Button}
        >
          {numberOfValues.length > 0 ? changeButton : addButton}
        </Button>
      </div>
    )
  }
