import reduceReducers from 'reduce-reducers'
import { Reducer, AnyAction } from 'redux'

import { locationsAutocompleteReducer } from './loadLocations'
import { professionsAutocompleteReducer } from './loadProfessions'
import { ApiAutocompleteState } from './types'

export { loadAutocompleteLocation } from './loadLocations'
export { loadAutocompleteProfessions } from './loadProfessions'

export const autocompleteReducer = reduceReducers<ApiAutocompleteState>(
  locationsAutocompleteReducer,
  professionsAutocompleteReducer
) as Reducer<ApiAutocompleteState, AnyAction>
