import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import { updateProfile } from '../../Store/profile/update'
import { Box, MatchingModal } from '../../Shared'
import { useEpiString, useStateSelector } from '../../Utils'
import {
  ProfileState,
  loadAutocompleteProfessions,
  IJobTitle,
} from '../../Store'

export interface PositionsProps {
  profile: ProfileState
}
export const JobTitles: FunctionComponent<PositionsProps> = ({ profile }) => {
  const t = useEpiString()
  const dispatch = useDispatch()
  const [activeModal, setOpenModal] = useState(false)
  const [currentValue, setCurrentValue] = useState('')
  const suggestedPositions = useStateSelector((state) =>
    state.autocomplete.professions.response?.suggestions?.map((opt) => ({
      name: opt.jobTitleWithHighLightedSearchTerm,
      id: opt.id,
    }))
  )

  const { jobTitles } = profile

  const handleAutocomplete = useCallback((value: string) => {
    setCurrentValue(value)
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    dispatch(loadAutocompleteProfessions(currentValue, signal))
    return () => {
      controller.abort()
    }
    // we only care if the current value has been updated here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue])

  const handleSave = useCallback(
    (newJobTitles: IJobTitle[]) => {
      dispatch(
        updateProfile({ jobTitles: newJobTitles, isSavedAfterMigration: true })
      )
    },
    [dispatch]
  )

  const openCloseModal = useCallback(() => {
    setOpenModal(!activeModal)
    setCurrentValue('')
  }, [activeModal])

  return (
    <div>
      <Box
        title={t('positions.title')}
        text={<>{t('positions.text')}</>}
        button={t('positions.button')}
        action={openCloseModal}
        values={jobTitles.map((p) => p.name)}
        notCompleteStepCharacter="1"
      />
      {activeModal && (
        <MatchingModal
          closeModal={openCloseModal}
          chipList={jobTitles}
          autocomplete={suggestedPositions}
          onSave={handleSave}
          autocompleteDisptach={handleAutocomplete}
          profile={profile}
          currentInput={currentValue}
        />
      )}
    </div>
  )
}
