import React, { FunctionComponent, useState, useCallback } from 'react'
import {
  Link,
  Button,
  Chip,
  Icons,
  ChipProps,
  Popover,
  Typography,
} from '@trr/common-component-library'
import { useDispatch } from 'react-redux'

import { useEpiString, useStateSelector } from '../../Utils'
import { updateConsent } from '../../Store'

import { ActivationModal } from './ActivationModal'
import s from './Activation.module.scss'

export const Activation: FunctionComponent = () => {
  const t = useEpiString()
  const [modalOpen, setModalOpen] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const dispatch = useDispatch()

  const profile = useStateSelector((state) => state.profile)

  const activateClickHandler = useCallback(() => {
    setModalOpen(true)
  }, [])
  const modalCancelHandler = useCallback(() => {
    setModalOpen(false)
  }, [])
  const modalConfirmHandler = useCallback(() => {
    dispatch(updateConsent(true))
    setModalOpen(false)
  }, [dispatch])
  const renderChip = (text: string, active: boolean) => {
    const iconProps: ChipProps = {
      color: 'purple',
      size: 'small',
    }
    if (active) {
      iconProps.icon = <Icons.CheckmarkIcon />
      iconProps.color = 'green'
    }
    return (
      <Chip data-testid="mp-status-chip" {...iconProps}>
        {text}
      </Chip>
    )
  }

  return (
    <div className={s.Activation} data-testid="mp-activation">
      <div className={s.Text}>
        {profile.active && (
          <>
            <Typography variant="body1">
              {' '}
              {t('activation.description')}
              &nbsp;
              <Link link={t('activation.link.url')} noChevron>
                {t('activation.link.text')}
              </Link>
            </Typography>
          </>
        )}
        <Typography variant="body1">
          <Link noChevron external link={t('gdpr.link.url')}>
            {t('gdpr.link.text')}
          </Link>
        </Typography>
      </div>
      {profile.isValid && !profile.active && (
        <Button
          testSelector="mp-consent-button"
          primary
          large
          customClass={s.Button}
          onClick={activateClickHandler}
        >
          {t('activation.button.activate')}
        </Button>
      )}
      <div className={s.Status}>
        {profile.active
          ? renderChip(t('activation.status.active'), true)
          : renderChip(t('activation.status.inactive'), false)}
        <Popover
          visible={tooltipVisible}
          setVisible={setTooltipVisible}
          popperPlacement="bottom"
          referenceElement={
            <button className={s.TooltipButton}>
              <Icons.InformationFilledIcon color={s.purple100} size={16} />
            </button>
          }
        >
          <div className={s.Tooltip}>
            <p>{t('activation.status.tooltip')}</p>
          </div>
        </Popover>
      </div>

      {modalOpen && (
        <ActivationModal
          onCancel={modalCancelHandler}
          onConfirm={modalConfirmHandler}
        />
      )}
    </div>
  )
}
