import { createActions, Action, handleActions } from 'redux-actions'
import { Dispatch, Reducer } from 'redux'
import { getOIDCUser } from '@trr/app-shell-communication'

import { post, ErrorResponse } from '../../Utils'
import { ThunkAction } from '../../Store'

import { CvApiModel, CvState } from './types'
import { initialCvState } from './initialCvState'

export const {
  uploadCvInitiated,
  uploadCvFullfilled,
  uploadCvRejected,
} = createActions({
  uploadCvInitiated: () => undefined,
  uploadCvFullfilled: (payload: CvApiModel) => payload,
  uploadCvRejected: (payload: ErrorResponse) => payload,
})

export const uploadPdf = (blob: File): ThunkAction => (dispatch: Dispatch) => {
 
  const OIDCUser = getOIDCUser()
  const userId = OIDCUser?.profile?.sub || ''
  
  const formData = new FormData()
  formData.append('file', blob)
  formData.append('pdfType', '0')
  formData.append('userId', userId)

  return post({
    body: formData,
    multipart: true,
    dispatch,
    url: `/api/cv/Pdf/Upload`,
    initiated: uploadCvInitiated,
    fulfilled: uploadCvFullfilled,
    rejected: uploadCvRejected,
  })
}

// Must use any to allow reducers to have different payload types.
// Fix by explicitly typing result as correct type below.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cvUploadReducer = handleActions<any>(
  {
    [uploadCvInitiated.toString()]: (state: CvState) => ({
      ...state,
      error: null,
      loading: true,
    }),
    [uploadCvFullfilled.toString()]: (
      state: CvState,
      action: Action<CvApiModel>
    ) => ({
      ...state,
      uploadedCv: action.payload,
      loading: false,
    }),
    [uploadCvRejected.toString()]: (
      state: CvState,
      action: Action<ErrorResponse>
    ) => ({
      ...state,
      loading: false,
      error: {
        status: action.payload.status,
      },
    }),
  },

  initialCvState
) as Reducer<CvState>
