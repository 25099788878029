import reduceReducers from 'reduce-reducers'
import { AnyAction, Reducer } from 'redux'

import { CvState } from './types'
import { cvUploadReducer } from './update'
import { cvLoadReducer } from './load'

export * from './types'
export * from './initialCvState'
export * from './load'
export * from './update'

export const cvReducer = reduceReducers<CvState>(
  cvUploadReducer,
  cvLoadReducer
) as Reducer<CvState, AnyAction>
