import React, {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'

import {
  ProfileState,
  updateProfile,
  loadAutocompleteLocation,
} from '../../Store'
import { ILocatedIn } from '../../Store/autocomplete/types'
import { Box } from '../../Shared'
import { useEpiString, useStateSelector } from '../../Utils'
import { EnglishToSwedishLocationTypes } from '../../Utils/Helpers'
import { LocationModal } from '../../Shared/MatchingModal/LocationModal'
import { loadCountryLocations } from '../../Store/autocomplete/loadLocations'

export interface LocationsProps {
  profile: ProfileState
}
export const Locations: FunctionComponent<LocationsProps> = ({ profile }) => {
  const t = useEpiString()
  const [activeModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()
  const autocompleteLocation = useStateSelector((state) =>
    state.autocomplete.locations.response?.locationInformation.map((opt) => ({
      name: opt.nameWithHighLightedSearchTerm,
      type: opt.type,
    }))
  )

  const openCloseModal = useCallback(() => {
    setOpenModal(!activeModal)
    dispatch(loadAutocompleteLocation(''))
  }, [activeModal, dispatch])

  const handleSave = useCallback(
    (detailedLocations: ILocatedIn[]) => {
      dispatch(
        updateProfile({
          locationInformations: detailedLocations,
        })
      )
    },
    [dispatch]
  )

  const getCountries = useCallback(() => {
    dispatch(loadCountryLocations('norge'))
    dispatch(loadCountryLocations('danmark'))
  }, [dispatch])

  useEffect(() => {
    getCountries()
  }, [getCountries])

  const handleAutocomplete = useCallback(
    (value: string) => {
      dispatch(loadAutocompleteLocation(value))
    },
    [dispatch]
  )
  return (
    <div>
      <Box
        title={t('locations.title')}
        text={<>{t('locations.text')}</>}
        button={t('locations.button')}
        notCompleteStepCharacter="2"
        values={
          profile.locationInformations.length > 0
            ? profile.locationInformations.map(
                (item) =>
                  `${item.name}${
                    item.type.toLowerCase() !== 'country'
                      ? ', ' + EnglishToSwedishLocationTypes(item.type)
                      : ''
                  }`
              )
            : []
        }
        action={openCloseModal}
      />
      {activeModal && (
        <LocationModal
          closeModal={openCloseModal}
          chipsListDetailed={profile.locationInformations}
          autocomplete={autocompleteLocation}
          onSaveDetailed={handleSave}
          autocompleteDisptach={handleAutocomplete}
          profile={profile}
          allowCreate={false}
          searchCountries={getCountries}
          selectedCountries={profile.locationInformations.filter(
            (l) => l.type.toLowerCase() === 'country'
          )}
        />
      )}
    </div>
  )
}
