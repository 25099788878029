import { createActions, Action, handleActions } from 'redux-actions'
import { Dispatch, AnyAction, Reducer } from 'redux'
import { getOIDCUser } from '@trr/app-shell-communication'

import { get, ErrorResponse } from '../../Utils'

import { ApiProfileModel, ProfileState } from './types'
import { initialProfileState } from './initialProfileState'

export const {
  loadProfileInitiated,
  loadProfileFulfilled,
  loadProfileRejected,
} = createActions({
  loadProfileInitiated: () => undefined,
  loadProfileFulfilled: (payload: ApiProfileModel) => payload,
  loadProfileRejected: (payload: ErrorResponse) => payload,
})

export const loadProfile = () => (dispatch: Dispatch<AnyAction>) => {
  return get({
    dispatch,
    url: `/client-match/profile/${getOIDCUser().profile.sub}`,
    initiated: loadProfileInitiated,
    fulfilled: loadProfileFulfilled,
    rejected: loadProfileRejected,
  })
}

// Must use any to allow reducers to have different payload types.
// Fix by explicitly typing result as correct type below.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const profileLoadReducer = handleActions<any>(
  {
    [loadProfileInitiated.toString()]: (): ProfileState => ({
      ...initialProfileState,
      loading: true,
    }),
    [loadProfileFulfilled.toString()]: (
      state: ProfileState,
      action: Action<ApiProfileModel>
    ): ProfileState => {
      // Handle initial empty reponse (204)
      const payload = action.payload || initialProfileState
      return {
        ...state,
        id: payload.id,
        positions: payload.positions,
        jobTitles: payload.jobTitles,
        isSavedAfterMigration: payload.isSavedAfterMigration,
        isAutoMigrated: payload.isAutoMigrated,
        locationInformations: payload.locationInformations,
        cvPdf: payload.cvPdf,
        additionalInformation: payload.additionalInformation,
        active: payload.active,
        isValid: payload.isValid,
        nonActiveReasons: payload.nonActiveReasons,
        loading: false,
      }
    },
    [loadProfileRejected.toString()]: (
      state: ProfileState,
      action: Action<ErrorResponse>
    ): ProfileState => ({
      ...state,
      loading: false,
      error: {
        status: action.payload.status,
      },
    }),
  },

  initialProfileState
) as Reducer<ProfileState>
