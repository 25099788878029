import { CvState } from './types'

export const initialCvState: CvState = {
  cvList: [
    {
      pdfId: '',
      userId: '',
      fileName: '',
      pdfType: 0,
      created: '',
      createdAction: '',
    },
  ],
  uploadedCv: {
    pdfId: '',
    userId: '',
    fileName: '',
    pdfType: 0,
    created: '',
    createdAction: '',
  },
  selectedCv: {
    id: "",
    name: "",
  },
  error: null,
  loading: false,
}
