export const useTranslateAdditionalInfo = (word: string) => {
  switch (word) {
    case 'FullTime':
      return 'Heltid'
    case 'PartTime':
      return 'Deltid'
    case 'Employee':
      return 'Anställd'
    case 'Consultant':
      return 'Konsult'
    case 'RemoteWork':
      return 'Distans'
    case 'Freelance':
      return 'Frilans'
    default:
      return word
  }
}
