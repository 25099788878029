export const SwedishToEnglishLocationTypes = (type: string) => {
  if (type) {
    switch (type.toLocaleLowerCase()) {
      case 'län':
        return 'County'
      case 'tätort':
        return 'City'
      case 'kommun':
        return 'Municipality'
      default:
        return type
    }
  } else {
    return ''
  }
}
