import { AnyAction, Dispatch, Reducer } from 'redux'
import { Action, createActions, handleActions } from 'redux-actions'

import { get, ErrorResponse } from '../../Utils'

import { initialAutoCompleteState } from './initialAutocompleteState'
import {
  ApiAutocompleteState,
  AutocompleteErrorState,
  IAutocompleteProfession,
} from './types'

export const {
  loadAutocompleteProfessionsInitiated,
  loadAutocompleteProfessionsFulfilled,
  loadAutocompleteProfessionsRejected,
} = createActions({
  loadAutocompleteProfessionsInitiated: () => ({}),
  loadAutocompleteProfessionsFulfilled: (payload: IAutocompleteProfession[]) =>
    payload,
  loadAutocompleteProfessionsRejected: (payload: AutocompleteErrorState) =>
    payload,
})

export const loadAutocompleteProfessions =
  (term: string, signal: AbortSignal) => (dispatch: Dispatch<AnyAction>) => {
    if (!term || term.length < 2) {
      return dispatch(loadAutocompleteProfessionsFulfilled([]))
    } else {
      return get({
        url: `/ReferenceData/jobtitles/v2/suggestions/${term}`,
        dispatch,
        initiated: loadAutocompleteProfessionsInitiated,
        fulfilled: loadAutocompleteProfessionsFulfilled,
        rejected: loadAutocompleteProfessionsRejected,
        signal,
      })
    }
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const professionsAutocompleteReducer = handleActions<any>(
  {
    [loadAutocompleteProfessionsInitiated.toString()]: () => ({
      ...initialAutoCompleteState,
      professions: {
        ...initialAutoCompleteState,
        loading: true,
        error: null,
      },
    }),
    [loadAutocompleteProfessionsFulfilled.toString()]: (
      state: ApiAutocompleteState,
      action: Action<IAutocompleteProfession[]>
    ) => ({
      ...state,
      professions: {
        response: {
          suggestions: action.payload.map((load) => ({
            ...load,
            jobTitleWithHighLightedSearchTerm:
              load.jobTitleWithHighLightedSearchTerm,
          })),
        },
        loading: false,
      },
    }),
    [loadAutocompleteProfessionsRejected.toString()]: (
      state: ApiAutocompleteState,
      action: Action<ErrorResponse>
    ) => ({
      ...state,
      professions: {
        loading: false,
        error: {
          status: action.payload.status,
        },
      },
    }),
  },

  initialAutoCompleteState
) as Reducer<ApiAutocompleteState>
