import { Action, createActions, handleActions } from 'redux-actions'
import { AnyAction, Dispatch, Reducer } from 'redux'
import { getOIDCUser } from '@trr/app-shell-communication'

import { get, ErrorResponse, downloadFile, previewFile } from '../../Utils'
import { ThunkAction } from '../../Store'

import { initialCvState } from './initialCvState'
import { CvApiModel, CvState, CvSelectedModel } from './types'

const getUserId = (): string => {
  const OIDCUser = getOIDCUser()
  const sub = OIDCUser?.profile?.sub || ''
  return sub
}

export const {
  getuploadedCvInitiated,
  getuploadedCvFulfilled,
  getuploadedCvRejected,
} = createActions({
  getuploadedCvInitiated: () => ({}),
  getuploadedCvFulfilled: (payload: CvApiModel[]) =>
    payload
      .filter((item) => item.pdfType === 0)
      .sort((a, b) => (a.created > b.created ? -1 : 1)),
  getuploadedCvRejected: (payload: ErrorResponse) => payload,
})

export const loadCvList = () => (dispatch: Dispatch<AnyAction>) => {
  return get({
    dispatch,
    url: `/api/cv/Pdf/GetByUserId/${getUserId()}`,
    initiated: getuploadedCvInitiated,
    fulfilled: getuploadedCvFulfilled,
    rejected: getuploadedCvRejected,
  })
}

export const {
  getDownloadedCvInitiated,
  getDownloadedCvFulfilled,
  getDownloadedCvPreviewFulfilled,
  getDownloadedCvRejected,
} = createActions({
  getDownloadedCvInitiated: () => ({}),
  getDownloadedCvFulfilled: (
    file: Blob,
    fileName: string,
    elementRef?: React.RefObject<HTMLElement>
  ) => {
    downloadFile(file, fileName, elementRef)
  },
  getDownloadedCvPreviewFulfilled: (
    file: Blob,
    elementRef?: React.RefObject<HTMLElement>
  ) => {
    previewFile(file, elementRef)
  },
  getDownloadedCvRejected: (payload: ErrorResponse) => payload,
})

export const loadCvFile =
  (
    pdfId: string | undefined,
    fileName: string | undefined,
    elementRef?: React.RefObject<HTMLElement>
  ): ThunkAction =>
  (dispatch) => {
    return get({
      dispatch,
      url: `/api/cv/Pdf/Get?userId=${getUserId()}&pdfId=${pdfId}`,
      initiated: getDownloadedCvInitiated,
      fulfilled: (payload: Blob) =>
        getDownloadedCvFulfilled(payload, fileName, elementRef),
      rejected: getDownloadedCvRejected,
      isBlob: true,
    })
  }

export const previewCvFile =
  (
    pdfId: string | undefined,
    elementRef?: React.RefObject<HTMLElement>
  ): ThunkAction =>
  (dispatch) => {
    return get({
      dispatch,
      url: `/api/cv/Pdf/Get?userId=${getUserId()}&pdfId=${pdfId}`,
      initiated: getDownloadedCvInitiated,
      fulfilled: (payload: Blob) =>
        getDownloadedCvPreviewFulfilled(payload, elementRef),
      rejected: getDownloadedCvRejected,
      isBlob: true,
    })
  }

export const { setChosenCVAction } = createActions({
  setChosenCVAction: (data: CvSelectedModel) => data,
})

export const setChosenCV =
  (data: CvSelectedModel) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setChosenCVAction(data))
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cvLoadReducer = handleActions<any>(
  {
    [getuploadedCvInitiated.toString()]: () => ({
      ...initialCvState,
      loading: true,
      error: null,
    }),
    [getuploadedCvFulfilled.toString()]: (
      state: CvState,
      action: Action<CvApiModel[]>
    ) => {
      const payload = action.payload || initialCvState
      return {
        ...state,
        cvList: payload,
        loading: false,
      }
    },
    [getuploadedCvRejected.toString()]: (
      state: CvState,
      action: Action<ErrorResponse>
    ) => ({
      ...state,
      error: {
        status: action.payload.status,
      },
      loading: false,
    }),
    [setChosenCVAction.toString()]: (
      state: CvState,
      action: Action<CvSelectedModel>
    ) => ({
      ...state,
      selectedCv: action.payload,
      loading: false,
    }),
    [getDownloadedCvInitiated.toString()]: () => ({
      ...initialCvState,
      loading: true,
    }),
    [getDownloadedCvFulfilled.toString()]: () => ({
      ...initialCvState,
      loading: false,
    }),
    [getDownloadedCvPreviewFulfilled.toString()]: () => ({
      ...initialCvState,
      loading: false,
    }),
    [getDownloadedCvRejected.toString()]: (
      state: CvState,
      action: Action<ErrorResponse>
    ) => ({
      ...initialCvState,
      loading: false,
      error: {
        status: action.payload.status,
      },
    }),
  },

  initialCvState
) as Reducer<CvState>
