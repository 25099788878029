export const EnglishToSwedishLocationTypes = (type: string) => {
  if (type) {
    switch (type.toLocaleLowerCase()) {
      case 'county':
        return 'län'
      case 'city':
        return 'tätort'
      case 'municipality':
        return 'kommun'
      default:
        return type
    }
  } else {
    return ''
  }
}
