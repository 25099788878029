import { Checkbox } from '@trr/common-component-library'
import React, { FunctionComponent } from 'react'

import s from './CheckBoxGroup.module.scss'

export interface CheckBoxGroupProps {
  checkBoxes: { name: string; value: boolean; label: string }[]
  title: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const CheckBoxGroup: FunctionComponent<CheckBoxGroupProps> = ({
  checkBoxes,
  title,
  handleChange,
}) => {
  return (
    <div className={s.section}>
      <div className={s.subTitle}>{title}</div>
      {checkBoxes.map((item) => {
        return (
          <Checkbox
            name={item.name}
            customClass={s.checkbox}
            key={item.name}
            label={item.label}
            onChange={handleChange}
            checked={item.value}
          />
        )
      })}
    </div>
  )
}
