import React, { FunctionComponent, useState, useCallback } from 'react'
import { Button } from '@trr/common-component-library'

import { useEpiString, useStateSelector } from '../../Utils'
import { IUser } from '../../App.types'

import { ShowProfileModal } from './ShowProfileModal'
import s from './ShowProfile.module.scss'

interface ShowProfileProps {
  userData: IUser
}

export const ShowProfile: FunctionComponent<ShowProfileProps> = ({
  userData,
}) => {
  const t = useEpiString()
  const [modalOpen, setModalOpen] = useState(false)

  const isActive = useStateSelector((state) => state.profile.active)

  const modalCloseHandler = useCallback(() => {
    setModalOpen(false)
  }, [])
  const modalShowHandler = useCallback(() => {
    setModalOpen(true)
  }, [])

  return (
    <div className={s.showProfileButton} data-testid="show-profile-button">
      <div className={s.Text}>
        {isActive && (
          <Button tertiary customClass={s.Button} onClick={modalShowHandler}>
            {t('showprofile.modal.button')}
          </Button>
        )}
      </div>

      {modalOpen && (
        <ShowProfileModal userData={userData} closeModal={modalCloseHandler} />
      )}
    </div>
  )
}
