import React from 'react'

import { IDevice } from '../../App.types'

export const DeviceProviderContext = React.createContext<IDevice>({
  isMobile: false,
  isDesktop: false,
  isTablet: false,
  isXL: false,
})

export const DeviceProvider = DeviceProviderContext.Provider
