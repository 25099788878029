import React, { FunctionComponent, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog } from '@trr/common-component-library'
import {
  IMatchingProfileData,
  MatchingProfile,
} from '@trr/matching-profile-library'

import { useStateSelector } from '../../../Utils'
import { EnglishToSwedishLocationTypes } from '../../../Utils/Helpers'
import { IUser } from '../../../App.types'
import { loadCvFile, previewCvFile } from '../../../Store'

import s from './ShowProfileModal.module.scss'

export interface ShowProfileModalProps {
  closeModal: () => void
  userData: IUser
}

export const ShowProfileModal: FunctionComponent<ShowProfileModalProps> = ({
  closeModal,
  userData,
}) => {
  const { profile } = useStateSelector((state) => state)
  const dispatch = useDispatch()
  const elementRef = useRef<HTMLDivElement>(null)

  const userProfile: IMatchingProfileData = {
    showCv: true,
    getCv: () =>
      dispatch(loadCvFile(profile.cvPdf?.id, profile.cvPdf?.name, elementRef)),
    previewCv: () => dispatch(previewCvFile(profile.cvPdf?.id, elementRef)),
    id: profile?.id,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    preferredFirstName: userData?.preferredFirstName,
    email: userData?.email,
    mobilePhone: userData?.mobilePhone,
    matchingData: {
      jobTitles: profile?.jobTitles,
      isSavedAfterMigration: profile.isSavedAfterMigration,
      locations: profile.locationInformations.map(
        (item) =>
          `${item.name}${
            item.type !== 'Country'
              ? ', ' + EnglishToSwedishLocationTypes(item.type)
              : ''
          }`
      ),
      additionalInformation: profile?.additionalInformation,
    },
    socialMedia:
      (!userData.linkedin && !userData.socialMedia) ||
      userData.linkedin?.length === 0
        ? []
        : userData.linkedin && userData.linkedin?.length !== 0
        ? [{ type: 'linkedin', url: userData.linkedin }]
        : userData.socialMedia,
  }

  return (
    <Dialog
      size="medium"
      heading=""
      onClose={closeModal}
      className={s.profilePreviewModal}
    >
      <div ref={elementRef}>
        <MatchingProfile {...userProfile} />
      </div>
    </Dialog>
  )
}
