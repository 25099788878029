import { getOIDCUser } from '@trr/app-shell-communication'
import { either, isNil, isEmpty } from 'ramda'

import { getConfig } from '..'

import { IFetchRequestType, IRequestType } from './Network.types'

type MapToOutput<I, U = I> = (v: I) => U

export const checkNullUndefined: MapToOutput<unknown, boolean> = either(
  isEmpty,
  isNil
)

export const baseUrl = (url: string) =>
  url.slice(-4) === 'json' // if request is a json file, retrieve it from public
    ? // eslint-disable-next-line no-undef
      `${process.env.PUBLIC_URL}${url}`
    : `${getConfig().API_URL}${url}`

// append header to bypass cache if cookie set
export const shouldUseCache = () =>
  sessionStorage.getItem('trr-force-pass') === null
    ? ''
    : { 'x-trr-force-pass': true }

// construct headers for request
const requestHeaders = ({
  id_token,
  body,
  method,
  multipart,
  signal = undefined,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  // Directly forcing a 'multipart/form-data' will not automatically generate boundaries, if file is provided, dont set content-type and content-type and boundaries will automatically be generated
  const headerContentType = multipart
    ? {}
    : { 'Content-type': 'application/json' }

  const abortSignal = { signal } ?? {}
  return {
    headers: {
      Authorization: `Bearer ${id_token}`,
      'Accept-Language': 'sv',
      ...headerContentType,
      ...shouldUseCache(),
    },
    method,
    body,
    ...abortSignal,
  }
}

// Handle HTTP errors since fetch won't.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleResponse = (response: any) => {
  const acceptedStatusCodes = [200, 202, 204]
  const { status } = response

  if (acceptedStatusCodes.includes(status)) {
    return response
  }
  throw new Error(status)
}

// fetch request
export const fetchRequest = ({
  body,
  method,
  id_token,
  _fetch = fetch,
  requestUrl,
  multipart,
  absolutePath,
  signal = undefined,
}: IFetchRequestType) => {
  if (absolutePath) {
    return _fetch(absolutePath)
  }
  return _fetch(
    baseUrl(requestUrl),
    requestHeaders({ id_token, body, method, multipart, signal })
  )
}

// base for all requests
const baseRequest =
  <TResponse,>({ method }: { method: string }) =>
  async ({
    _fetch = fetch,
    aborted,
    dispatch,
    appendSub = false,
    body,
    fulfilled,
    initiated,
    rejected,
    url,
    multipart,
    queryParams,
    absolutePath,
    isBlob,
    signal = undefined,
  }: IRequestType) => {
    dispatch(initiated())

    const OIDCUser = getOIDCUser()
    const id_token = OIDCUser?.id_token || ''
    const userId = OIDCUser?.profile?.sub || ''

    try {
      const queryParameters = checkNullUndefined(queryParams)
        ? ''
        : `?${queryParams?.replace('?', '') ?? ''}`

      // find better way to catch
      const response = await fetchRequest({
        _fetch,
        method,
        body,
        id_token,
        requestUrl: appendSub
          ? `${url}/${userId}${queryParameters}`
          : `${url}${queryParameters}`,
        multipart,
        absolutePath,
        signal,
      })

      const actionHandler = response.ok ? fulfilled : rejected

      if (isBlob) {
        const blob = await response.blob()
        return dispatch(actionHandler(blob))
      }
      const expectNoResponseBody = ['DELETE'].includes(method)
      const responseHasNoContent = response.status === 204

      if (expectNoResponseBody || responseHasNoContent) {
        return dispatch(actionHandler())
      }

      const json = (await response.json()) as TResponse

      return dispatch(actionHandler(json))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (aborted && error.name === 'AbortError') {
        return dispatch(aborted())
      }
      return dispatch(rejected(error))
    }
  }

export const get = baseRequest({ method: 'GET' })
export const post = baseRequest({ method: 'POST' })
export const deleteRequest = baseRequest({ method: 'DELETE' })
export const put = baseRequest({ method: 'PUT' })
