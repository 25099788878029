import React, { useCallback } from 'react'
import {
  Dropdown,
  ISelectOption,
  Typography,
} from '@trr/common-component-library'

import s from './Autocomplete.module.scss'

export interface AutocompleteProps {
  placeholder: string
  label: string
  options: ISelectOption[] | undefined
  onBlur?: () => void
  onType?: (value: string) => void
  onChange: (options: ISelectOption) => void
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customClass?: any
  icon?: JSX.Element
  allowCreate?: boolean
  showNoResult?: boolean
}

export const Autocomplete: React.FunctionComponent<AutocompleteProps> = ({
  customClass = {},
  ...props
}) => {
  customClass.control = (base: object) => ({
    ...base,
    background: s.white,
    minHeight: '52px',
    padding: '0',
    margin: '0',
  })
  customClass.valueContainer = (base: object) => ({
    ...base,
    padding: '0px 0px 0px 8px',
  })
  customClass.Label = (base: object) => ({
    ...base,
    fontSize: '12px',
  })
  customClass.singleValue = (base: object) => ({
    ...base,
    top: '50%',
  })

  const handleLoadOptions = React.useCallback(
    (value: string | undefined) => {
      value && props.onType && props.onType(value)
    },
    [props]
  )

  const filterOptions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_option: unknown, _inputValue: string) => true,
    []
  )

  return (
    <div className={s.Wrapper}>
      <Typography component="h2" className={s.Label} variant="h5">
        {props.label}
      </Typography>
      <Dropdown
        allowCreate={props.allowCreate}
        customFilterOptions={filterOptions}
        testSelector={'input-field-selector'}
        onInputChange={handleLoadOptions}
        onChange={props.onChange}
        onBlur={props.onBlur}
        selectOptions={props.options}
        customClass={customClass}
        icon={props.icon}
        value={{ value: '', label: '' }}
        clearable={false}
        noResultMinLength={props?.showNoResult ? 2 : 99}
      />
    </div>
  )
}
