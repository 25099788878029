import { createActions, Action, handleActions } from 'redux-actions'
import { Reducer } from 'redux'

import { put, ErrorResponse } from '../../Utils'
import { ThunkAction } from '../../Store'

import { ApiProfileModel, ProfileState, PutApiProfileModel } from './types'
import { initialProfileState } from './initialProfileState'

export const {
  updateProfileInitiated,
  updateProfileFulfilled,
  updateProfileRejected,
} = createActions({
  updateProfileInitiated: () => undefined,
  updateProfileFulfilled: (payload: ApiProfileModel) => payload,
  updateProfileRejected: (payload: ErrorResponse) => payload,
})

export const updateProfile =
  (payload: Partial<PutApiProfileModel>): ThunkAction =>
  (dispatch, getState) => {
    const current = getState().profile
    // Only pick existing API Model props to not overpost.
    const body: PutApiProfileModel = {
      id: current.id,
      locationInformations: current.locationInformations,
      positions: current.positions,
      jobTitles: current.jobTitles,
      cvPdf: current.cvPdf,
      additionalInformation: current.additionalInformation,
      isSavedAfterMigration: current.isSavedAfterMigration,
      ...payload,
    }
    return put({
      body: JSON.stringify(body),
      dispatch,
      url: `/client-match/profile/${current.id}`,
      initiated: updateProfileInitiated,
      fulfilled: updateProfileFulfilled,
      rejected: updateProfileRejected,
    })
  }

// Must use any to allow reducers to have different payload types.
// Fix by explicitly typing result as correct type below.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const profileUpdateReducer = handleActions<any>(
  {
    [updateProfileInitiated.toString()]: (
      state: ProfileState
    ): ProfileState => ({
      ...state,
      error: null,
      loading: true,
    }),
    [updateProfileFulfilled.toString()]: (
      state: ProfileState,
      action: Action<ApiProfileModel>
    ): ProfileState => {
      const { payload } = action
      return {
        ...state,
        ...payload,
        loading: false,
      }
    },
    [updateProfileRejected.toString()]: (
      state: ProfileState,
      action: Action<ErrorResponse>
    ): ProfileState => ({
      ...state,
      loading: false,
      error: {
        status: action.payload.status,
      },
    }),
  },

  initialProfileState
) as Reducer<ProfileState>
