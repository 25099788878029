import React from 'react'
import { Provider } from 'react-redux'

import { DeviceProvider } from './Utils/DeviceProvider'
import { AppShellConfigurationProps } from './App.types'
import { KeyRoute, AppShellRouter } from './Router'
import { store } from './Store'
import { LandingPage } from './Pages'
import { EpiStringProvider, FeatureFlagProvider } from './Utils'

const App: React.FunctionComponent<AppShellConfigurationProps> = ({
  currentKey,
  content,
  enabledFeatures,
  user,
  device,
}) => {
  return (
    <Provider store={store}>
      <DeviceProvider value={device}>
        <FeatureFlagProvider value={enabledFeatures}>
          <AppShellRouter currentKey={currentKey}>
            <KeyRoute urlKey={'profil'}>
              <EpiStringProvider value={content.main}>
                <LandingPage userData={user} />
              </EpiStringProvider>
            </KeyRoute>
          </AppShellRouter>
        </FeatureFlagProvider>
      </DeviceProvider>
    </Provider>
  )
}

export default App
